import Image from 'next/future/image';

import { getSection } from '../utility';

const PartnerComponent = (props: any) => {
  const section = getSection(props);
  const title = section && section[12] && section[12].Title;
  const logos = section && section[12] && section[12].Logos;
  return (
    <>
      <section className="partners">
        <div className="container">
          <p>{title}</p>
          <div className="partners-logo mt-5">
            <div className="row justify-content-center row-cols-3 row-cols-md-12 row-cols-auto justify-content-sm-between justify-content-md-between justify-content-lg-between align-items-center gx-2">
              {logos &&
                logos.map((item: any) => (
                  <div
                    key={item?.Image.data?.attributes?.alternativeText}
                    className="col-sm-auto col-lg-auto text-center mt-2"
                  >
                    <Image
                      src={item?.Image.data?.attributes?.url}
                      alt={item?.Image.data?.attributes?.alternativeText}
                      className="logo"
                      width="130"
                      height="80"
                      sizes="(max-width: 375px) 60px, (max-width: 576px) 70px, (max-width: 768px) 105px, (max-width: 992px) 140px, (max-width: 1280px) 165px"
                    />
                  </div>
                ))}
            </div>
          </div>
          <></>
        </div>
      </section>
    </>
  );
};

export default PartnerComponent;
